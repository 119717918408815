import { Credit_route_group } from "./Pages/Credit/Credit";
import { Dashboard_route_group } from "./Pages/Dashboard";
import { Invoice_route_group } from "./Pages/Invoice/Invoice";
import { Products_route_group } from "./Pages/Products";
import { Purchase_route_group } from "./Pages/Purchase";
import { Sales_route_group } from "./Pages/Sales";
import { Settings_route_group } from "./Pages/Settings";
import { Staff_route_group } from "./Pages/Staff";

export const Pages_route_group = [
  ...Dashboard_route_group,
  ...Products_route_group,
  ...Invoice_route_group,
  ...Sales_route_group,
  ...Purchase_route_group,
  ...Staff_route_group,
  ...Settings_route_group,
  ...Credit_route_group,
];
